<template>
  <Layout :hbgsrc="bg">
    <div class="modal flex" slot="slogan">
      <div class="box">
        <h4 class="h4">智慧能源科技 <br />照亮人类美好未来</h4>
      </div>
    </div>
    <template slot="body">
      <template v-if="!$store.state.isPc">
        <MainSlogan>
          <template slot="title"
            >智慧能源零碳时代<br />绿水青山就是金山银山</template
          >
          <template slot="p1"
            >誉照科技集团致力于可再生能源与智慧能源的技术创新，通过智能化、可视化、数字化的创新理念，为人类打造美好智能新时代，真正实现人人都能用上充足、稳定的清洁能源。</template
          >
        </MainSlogan>
        <section class="one">
          <div class="list">
            <div class="item bb" v-for="item in oneList" :key="item.id">
              <el-image :src="item.src" fit="scal-down" class="bg"></el-image>
              <div class="modal">
                <h4>{{ item.title }}</h4>
                <p v-html="item.des"></p>
                <div class="logo flex">
                  <div class="logo-box flex">
                    <el-image :src="item.sign" fit="cover"></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="two">
          <h4>全国运营网络</h4>
          <p>
            誉照正加快布局运营网络，积极开拓市场，业务足迹覆盖全国多个地区。
          </p>
          <el-image :src="pic" fit="cover"></el-image>
        </section>
        <section class="three">
          <MainSlogan>
            <template slot="title">绿色能源投资全周期闭环</template>
          </MainSlogan>
          <div class="list">
            <div class="item flex" v-for="item in threeList" :key="item.id">
              <div class="item-left">
                <el-image :src="item.src" fit="cover"></el-image>
              </div>
              <div class="item-right">
                <h4>{{ item.title }}</h4>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="four">
          <MainSlogan>
            <template slot="title">成功案例</template>
            <template slot="p1">
              通过业务、产品、服务与技术赋能我们的零碳伙伴，共创低碳生态，共赢美好未来。
            </template>
          </MainSlogan>
          <div class="lists">
            <div class="items" v-for="item in fourList" :key="item.id">
              <el-image :src="item.src" fit="cover"></el-image>
              <div class="modal">
                <span class="name">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="five">
          <MainSlogan>
            <template slot="title">我们的服务</template>
            <template slot="p1">
              誉照通过一站式专业服务为全球用户，提供高标准、高品质、高效益的智慧能源电站综合解决方案。
            </template>
          </MainSlogan>
          <div class="list">
            <div class="item flex" v-for="item in fiveList" :key="item.id">
              <div class="item-left">
                <el-image :src="item.src" fit="cover"></el-image>
              </div>
              <div class="item-right">
                <h4>{{ item.title }}</h4>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-if="$store.state.isPc">
        <MainSlogan>
          <template slot="title"
            >智慧能源零碳时代，绿水青山就是金山银山</template
          >
          <template slot="p1"
            >誉照科技集团致力于可再生能源与智慧能源的技术创新，通过智能化、可视化、数字化的创新理念，为人类</template
          >
          <template slot="p2"
            >打造美好智能新时代，真正实现人人都能用上充足、稳定的清洁能源。</template
          >
        </MainSlogan>
        <section class="split-area flex">
          <div
            class="item bb flex"
            v-for="(item, index) in clist"
            :class="[active === item.id ? 'active' : '']"
            :key="item.id"
            @mouseenter="active = item.id"
            @mouseleave="active = ''"
          >
            <div class="item-box flex">
              <h5>{{ item.slogan }}</h5>
              <span>{{ item.p1 }}</span>
              <span>{{ item.p2 }}</span>
            </div>
            <div class="bg">
              <el-image :src="item.src" fit="cover"></el-image>
            </div>
            <div class="small-bg" :class="'small-bg' + index">
              <el-image :src="item.smallsrc" fit="cover"></el-image>
            </div>
          </div>
        </section>

        <MainSlogan>
          <template slot="title">全国运营网络</template>
          <template slot="p1"
            >誉照正加快布局运营网络，积极开拓市场，业务足迹覆盖全国多个地区。</template
          >
        </MainSlogan>
        <section class="pic-box">
          <PicWall :pic="pic"></PicWall>
        </section>

        <section class="in-swiper">
          <h4 class="h4">绿色能源投资全周期闭环</h4>
          <div class="swiper-box">
            <div class="swiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide flex"
                  v-for="item in step"
                  :key="item.id"
                >
                  <div class="swiper-cnt flex">
                    <span class="title">{{ item.title }}</span>
                    <span class="line"></span>
                    <div
                      class="shape flex"
                      @mouseenter="toggleIn(item.id)"
                      @mouseleave="toggleOut(item.id)"
                      :class="[inActive == item.id ? 'active' : '']"
                    >
                      <el-image :src="item.src" fit="cover"></el-image>
                      <span v-html="item.des"></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="custom-box flex">
              <div class="custom-swiper-button custom-swiper-button-prev">
                <el-image :src="swiperButton" fit="fill"></el-image>
              </div>
              <div class="swiper-pagination flex"></div>
              <div class="custom-swiper-button custom-swiper-button-next">
                <el-image :src="swiperButton" fit="fill"></el-image>
              </div>
            </div> -->
            </div>
          </div>
        </section>

        <MainSlogan>
          <template slot="title">成功案例</template>
          <template slot="p1"
            >通过业务、产品、服务与技术赋能我们的零碳伙伴，共创低碳生态，共赢美好未来。
          </template>
        </MainSlogan>

        <!-- 合作伙伴 -->
        <section class="friends">
          <div class="swiper-box">
            <div class="swiper-friends">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in sseg" :key="item.id">
                  <div class="swiper-cnt flex">
                    <el-image :src="item.src"></el-image>
                    <h6>{{ item.name }}</h6>
                  </div>
                </div>
              </div>

              <!-- <div class="custom-box flex">
              <div class="custom-swiper-button custom-swiper-button-prev">
                <el-image :src="swiperButton" fit="fill"></el-image>
              </div>
              <div class="swiper-pagination flex"></div>
              <div class="custom-swiper-button custom-swiper-button-next">
                <el-image :src="swiperButton" fit="fill"></el-image>
              </div>
            </div> -->
            </div>
          </div>
        </section>

        <section class="service">
          <MainSlogan>
            <template slot="title">我们的服务</template>
            <template slot="p1"
              >誉照通过一站式专业服务为全球用户提供高标准、高品质、高效益的智慧能源电站综合解决方案。
            </template>
          </MainSlogan>
          <section class="tabs">
            <el-tabs v-model="activeName">
              <el-tab-pane
                :name="item.name"
                v-for="item in service"
                :key="item.id"
              >
                <div class="custom-label flex" slot="label">
                  <el-image :src="item.src" fit="cover"></el-image>
                  <span>{{ item.title }}</span>
                </div>
                <div class="content-box bb flex">
                  <el-image :src="item.csrc" fit="cover"></el-image>
                  <div class="txt">
                    <h5>{{ item.ctitle }}</h5>
                    <p>
                      {{ item.cdes }}
                    </p>
                    <span>业务范围:{{ item.carea }}</span>
                  </div>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="配置管理" name="second">
              <div class="custom-label flex" slot="label">
                <el-image></el-image>
                <span>了解epc</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="角色管理" name="third">
              <div class="custom-label flex" slot="label">
                <el-image></el-image>
                <span>了解epc</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="定时任务补偿" name="fourth">
              <div class="custom-label flex" slot="label">
                <el-image></el-image>
                <span>了解epc</span>
              </div></el-tab-pane
            >
            <el-tab-pane label="定时任务补偿" name="fourth">
              <div class="custom-label flex" slot="label">
                <el-image></el-image>
                <span>了解epc</span>
              </div></el-tab-pane
            > -->
            </el-tabs>
          </section>
        </section>
      </template>
      <Applyin :bg="'#000000'" :color="'#fff'"></Applyin>
    </template>
  </Layout>
</template>

<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  import { Component, Vue } from "vue-property-decorator";
  import Layout from "@/components/Layout.vue"; // @ is an alias to /src
  // import AssignCard from "@/components/common/AssignCard.vue";
  import PicWall from "@/components/common/PicWall.vue";
  import MainSlogan from "@/components/common/MainSlogan.vue";
  import Applyin from "@/components/common/Applyin.vue";

  @Component({
    components: {
      Layout,
      // AssignCard,
      PicWall,
      MainSlogan,
      Applyin,
    },
  })
  export default class Investment extends Vue {
    data() {
      return {
        swiperButton: require("@/assets/home/img/arrawblack.png"),
        activeName: "first",
        bg: require("@/assets/invest/img/bg.png"),
        pic: require("@/assets/invest/img/map.png"),
        active: "",
        inActive: "",
        clist: [
          {
            id: 1,
            slogan: "分布式光伏",
            p1: "绿电赋能，零碳未来",
            p2: "让阳光延续到每一个美好夜晚",
            src: require("@/assets/invest/img/c1.png"),
            smallsrc: require("@/assets/invest/img/c11.png"),
          },
          {
            id: 2,
            slogan: "分布式风电",
            p1: "将风能转换为电能",
            p2: "风场和风景，尽享其中",
            src: require("@/assets/invest/img/c2.png"),
            smallsrc: require("@/assets/invest/img/c22.png"),
          },
          {
            id: 3,
            slogan: "集中式光伏",
            p1: "大型地面光伏电站",
            p2: "那一抹蓝点亮了荒漠与村落",
            src: require("@/assets/invest/img/c3.png"),
            smallsrc: require("@/assets/invest/img/c33.png"),
          },
          {
            id: 4,
            slogan: "智慧储能",
            p1: "能源储能与管理",
            p2: "高效管理能源的流动",
            src: require("@/assets/invest/img/c4.png"),
            smallsrc: require("@/assets/invest/img/c44.png"),
          },
        ],
        step: [
          {
            id: 1,
            title: "项目开发",
            src: require("@/assets/invest/img/s1.png"),
            des: "大数据测算选址<br />航拍及卫星双维度图谱运算",
          },
          {
            id: 2,
            title: "方案设计",
            src: require("@/assets/invest/img/s2.png"),
            des: "智慧能源数字化设计平台<br />智能硬件与智慧软件双向驱动",
          },
          {
            id: 3,
            title: "工程建设",
            src: require("@/assets/invest/img/s3.png"),
            des: "项目建设智能管理软件<br />标准化制定建设解决方案",
          },
          {
            id: 4,
            title: "资产管理",
            src: require("@/assets/invest/img/s4.png"),
            des: "综合能源管理系统集精算、<br/>运维、调度、管理。实现实<br/>端到云端的智能化解决方案<br/>打造即建即用的高效型资产",
          },
        ],
        sseg: [
          {
            id: 1,
            src: require("@/assets/invest/img/ss1.png"),
            name: "上饶万历时代",
          },
          {
            id: 2,
            src: require("@/assets/invest/img/ss2.png"),
            name: "上饶市广信大厦",
          },
          {
            id: 3,
            src: require("@/assets/invest/img/ss3.png"),
            name: "德兴市城南汽车站",
          },
        ],
        service: [
          {
            id: 1,
            name: "first",
            src: require("@/assets/invest/img/sss1.png"),
            title: "EPC",
            csrc: require("@/assets/invest/img/cs1.png"),
            ctitle: "EPC",
            cdes: "提供项目开发咨询、项目设计、工程建设、电站运维等高标准、高质量的全流程一站式服务。",
            carea: "中国",
          },
          {
            id: 2,
            name: "secd",
            src: require("@/assets/invest/img/sss2.png"),
            title: "BT建设转让",
            csrc: require("@/assets/invest/img/cs2.png"),
            ctitle: "BT建设转让",
            cdes: "提供电站开发、建设、转让等服务。业务范围涵盖集中式光伏电站，分布式光伏电站，集中式风电站，分散式风电站等类型的项目。",
            carea: "中国",
          },
          {
            id: 3,
            name: "thrid",
            src: require("@/assets/invest/img/sss3.png"),
            title: "电站开发咨询",
            csrc: require("@/assets/invest/img/cs3.png"),
            ctitle: "电站开发咨询",
            cdes: "专业、精准的选址、热点区域推荐、规模建议、规避投资风险。业务范围涵盖集中式光伏电站，分布式光伏电站，集中式风电站，分散式风电站等类型的项目。",
            carea: "中国",
          },
          {
            id: 4,
            name: "fourth",
            src: require("@/assets/invest/img/sss4.png"),
            title: "结构工程咨询",
            csrc: require("@/assets/invest/img/cs4.png"),
            ctitle: "工程咨询",
            cdes: "全专业全过程设计咨询、施工图纸优化设计、解决技术难题及成本控制，全方位和核验图纸中错、漏、缺，保障施工顺利进行。",
            carea: "中国",
          },
          {
            id: 5,
            name: "fifth",
            src: require("@/assets/invest/img/sss5.png"),
            title: "电站智能运维",
            csrc: require("@/assets/invest/img/cs5.png"),
            ctitle: "电站智能运维",
            cdes: "为客户提供全面的光伏电站运维服务，包括监控、检修及维护。通过能源管理系统实现对供配电系统的智能化安全监控与运维管理，降低电站运维成本，以数据驱动提供安全可靠性。",
            carea: "中国",
          },
        ],
        oneList: [
          {
            id: 1,
            title: "分布式光伏",
            des: "绿电赋能，零碳未来<br />让阳光延续到每一个美好夜晚",
            src: require("@/assets/invest/img/c1.png"),
            sign: require("@/assets/invest/img/c111.png"),
          },
          {
            id: 2,
            title: "分布式风电",
            des: "将风能转换为电能<br />风场和风景，尽享其中",
            src: require("@/assets/invest/img/c2.png"),
            sign: require("@/assets/invest/img/c222.png"),
          },
          {
            id: 3,
            title: "集中式光伏",
            des: "大型地面光伏电站<br />那一抹蓝点亮了荒漠与村落",
            src: require("@/assets/invest/img/c3.png"),
            sign: require("@/assets/invest/img/c333.png"),
          },
          {
            id: 4,
            title: "智慧储能",
            des: "能源储能与管理<br />高效管理能源的流动",
            src: require("@/assets/invest/img/c4.png"),
            sign: require("@/assets/invest/img/c444.png"),
          },
        ],
        threeList: [
          {
            id: 1,
            src: require("@/assets/invest/img/three1.png"),
            title: "项目开发",
            des: "大数据测算选址<br />航拍及卫星双纬度图谱运算",
          },
          {
            id: 2,
            src: require("@/assets/invest/img/three2.png"),
            title: "方案设计",
            des: "智慧能源数字化设计平台<br />智能硬件与智慧软件双向驱动",
          },
          {
            id: 3,
            src: require("@/assets/invest/img/three3.png"),
            title: "工程建设",
            des: "项目建设智能管理软件<br />标准化指定建设解决方案",
          },
          {
            id: 4,
            src: require("@/assets/invest/img/three4.png"),
            title: "资产管理",
            des: "综合能源管理系统集精算、运维、调度、<br />管理。实现实端到云端的智能化解决方案<br />打造即建即用的高效型资产",
          },
        ],
        fourList: [
          {
            id: 1,
            src: require("@/assets/invest/img/ss1.png"),
            name: "抚州市硕果时代",
          },
          {
            id: 2,
            src: require("@/assets/invest/img/ss2.png"),
            name: "上饶市检察院",
          },
          {
            id: 3,
            src: require("@/assets/invest/img/ss3.png"),
            name: "德兴市城南汽车站",
          },
        ],
        fiveList: [
          {
            id: 1,
            src: require("@/assets/invest/img/five1.png"),
            title: "EPC",
            des: "提供项目开发咨询、项目设计、工程建设、电站运维等高标准、高质量全流程一站式服务。",
          },
          {
            id: 2,
            src: require("@/assets/invest/img/five2.png"),
            title: "BT建设转让",
            des: "提供电站开发、建设、转让等服务。业务范围涵盖集中式光伏电站、分布式光伏电站、集中式风电站，分散式风电站等类型项目。",
          },
          {
            id: 3,
            src: require("@/assets/invest/img/five3.png"),
            title: "电站开发咨询",
            des: "专业、精准的选址、热点区域推荐、规模建议、规避投资风险。业务范围涵盖集中式光伏电站、分布式光伏电站、集中式风电站，分散式风电站等类型的项目。",
          },
          {
            id: 4,
            src: require("@/assets/invest/img/five4.png"),
            title: "工程咨询",
            des: "全专业全过程设计咨询、施工图纸优化设计、解决技术难题及成本控制，全方位和核验图纸中错、漏、缺，保障施工顺利进行。",
          },
          {
            id: 5,
            src: require("@/assets/invest/img/five5.png"),
            title: "电站智能运维",
            des: "为客户提供全面的光伏电站运维服务，包括监控、检修及维护。通过能源管理系统实现对供配电系统的智能化安全监控与运维管理，降低电站运维成本，以数据驱动提供安全可靠性。",
          },
        ],
      };
    }
    mounted() {
      this.$nextTick(() => {
        new Swiper(".swiper-friends", {
          // autoplay: {
          //   delay: 5000,
          // },
          slidesPerView: 3,
          spaceBetween: 20,
          autoplay: false,
          navigation: {
            nextEl: ".custom-swiper-button-next",
            prevEl: ".custom-swiper-button-prev",
          },
          loop: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      });
    }

    toggleIn(id) {
      this.inActive = id;
      console.log(id);
    }
    toggleOut(id) {
      this.inActive = "";
    }
  }
</script>

<style lang="less" scoped>
  .split-area {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 80px;
    .item {
      position: relative;
      width: 50%;
      height: 480px;
      color: #333;
      background: #cbcbcb;
      transition: all 0.4s;
      z-index: 2;
      &:first-of-type,
      &:last-of-type {
        background: #f6f6f6;
      }

      &.active {
        color: #fff;
        .bg {
          // background: url(~@/assets/invest/img/c1.png);
          opacity: 1;
        }
      }
      .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all 0.4s;
      }
      .small-bg {
        position: absolute;
        width: 120px;
        height: 120px;
        &.small-bg0 {
          bottom: 47px;
          right: 39px;
        }
        &.small-bg1 {
          bottom: 47px;
          left: 39px;
        }
        &.small-bg2 {
          top: 47px;
          right: 39px;
        }
        &.small-bg3 {
          top: 47px;
          left: 39px;
        }
      }
      .item-box {
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding-left: 37.5%;
        z-index: 2;

        h5 {
          font-size: 30px;
          width: 100%;
          margin: 127px 0 32px;
        }
        span {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &:nth-child(2n) {
        .item-box {
          text-align: right;
          align-items: flex-end;
          padding-right: 37.5%;
          padding-left: 0;
        }
      }
    }
  }
  .pic-box {
    width: 100%;
    max-width: 1200px;
    height: 1048px;
    margin: 0 auto;
    /deep/.section-picwall {
      height: 100%;
      margin-top: 0;
    }
  }
  .custom-box {
    justify-content: center;
    align-items: center;
    margin-top: 97px;
    margin-bottom: 93px;
    .custom-swiper-button {
      width: 40px;
      height: 14px;
      cursor: pointer;
      &.custom-swiper-button-prev {
        transform: rotate(180deg);
      }
    }
    .swiper-pagination {
      position: relative;
      bottom: 0;
      margin: 0 30px;
      /deep/.swiper-pagination-bullet {
        width: 50px;
        height: 2px;
        border-radius: 0;
        &.swiper-pagination-bullet-active {
          background: #f39800;
        }
      }
    }
  }
  .in-swiper {
    width: 100%;
    height: 700px;
    background: #f6f6f6;
    overflow: hidden;
    .h4 {
      margin: 77px auto 119px;
    }
    .swiper-box {
      width: 100%;
      max-width: 1236px;
      overflow: hidden;
      margin: 0 auto;
      .swiper {
        position: relative;
      }
      .swiper-slide {
        width: 25%;
        justify-content: center;
      }
      .swiper-cnt {
        color: #666;
        flex-direction: column;
        align-items: center;
        //   width: calc(20% - 93.6px);
        .el-image {
          width: 146px;
          height: 166px;
        }
        .title {
          font-size: 24px;
          margin-bottom: 18px;
        }
        .small {
          font-size: 16px;
          color: #999;
        }
        .line {
          width: 3px;
          height: 28px;
          background: #999;
          margin: 26px auto 36px;
        }
        .shape {
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &.active {
            .el-image {
              opacity: 1;
            }
            span {
              display: block;
            }
          }
          .el-image {
            opacity: 0.2;
            font-size: 30px;
            transition: all 0.4s;
            margin-bottom: 16px;
          }
          span {
            display: none;
            transition: all 0.4s;
            font-size: 14px;
          }
        }
        .pagination {
          position: relative;
          font-size: 18px;
          margin-top: 15px;
          &::before {
            content: "";
            position: absolute;
            left: -240%;
            top: 47%;
            width: 24px;
            height: 2px;
            background: #999;
          }
          &::after {
            content: "";
            position: absolute;
            right: -240%;
            top: 47%;
            width: 24px;
            height: 2px;
            background: #999;
          }
        }
      }
    }
  }

  .friends {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .swiper-box {
      overflow: hidden;
      margin-top: 80px;
      .swiper-slide {
        .swiper-cnt {
          flex-direction: column;
          text-align: left;
          .el-image {
            width: 100%;
            height: 429px;
          }
          > h6 {
            font-size: 20px;
            margin: 30px 0 100px;
          }
          > p {
            font-size: 14px;
            color: #333;
            margin: 0;
          }
        }
      }
    }
  }

  .service {
    overflow: hidden;
    background: #f6f6f6;
    padding-bottom: 80px;
    .tabs {
      width: 100%;
      max-width: 1200px;
      margin: 70px auto 0;
      /deep/.el-tabs__header {
        margin: 0;
        .el-tabs__nav-wrap::after {
          position: relative;
        }
      }
      /deep/.el-tabs__nav {
        width: 100%;
        z-index: 1;
      }
      /deep/ .el-tabs__active-bar {
        background: #ffc350;
        // width: 200px !important;
      }
      /deep/.el-tabs__item {
        position: relative;
        height: auto;
        width: 20%;
        box-sizing: content-box;
        color: #333;
        //   padding-right: 90px;
        padding: 0;
        &.is-active {
          color: #f39800;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #ffc350;
            // z-index: 99;
          }
        }
        .custom-label {
          flex-direction: column;
          align-items: center;
          .el-image {
            width: 80px;
            height: 82px;
            margin-bottom: 8px;
          }
          > span {
            font-size: 14px;
            color: inherit;
            line-height: initial;
            margin-bottom: 24px;
          }
        }
      }

      .content-box {
        width: 100%;
        height: 418px;
        align-items: center;
        background: #fff;
        border: 1px solid #ffd08d;
        padding: 68px 90px;
        // margin-top: -15px;
        .el-image {
          width: 43.589%;
          height: 100%;
          margin-right: 4.1%;
        }
        .txt {
          flex: 1;
          color: #333;
          text-align: left;
          > h5 {
            font-size: 30px;
            margin-bottom: 30px;
            margin-top: 0;
          }
          > p {
            font-size: 14px;
            margin-bottom: 30px;
          }
          > span {
            font-size: 14px;
          }
        }
      }
    }
  }

  /deep/.applyin {
    margin-top: 0;
  }

  @media screen and(max-width: 1024px) {
    /deep/.top {
      height: 440rem;

      .modal {
        box-sizing: border-box;
        padding: 0 24rem;
        .box {
          .h4 {
            font-size: 22rem;
            margin-bottom: 20rem;
          }
          p {
            max-width: 327rem;
          }
          .bg-title {
            width: 100%;
            font-size: 11rem;
            line-height: 15rem;
          }
        }
      }
    }
    /deep/.section-main-slogan {
      h4 {
        font-size: 22rem;
        line-height: 22rem;
        font-weight: normal;
        margin: 42rem auto 24rem;
      }
      p {
        font-size: 11rem;
        padding: 0 24rem;
      }
    }

    .one {
      padding: 0 24rem;
      .list {
        margin-top: 40rem;
        .item {
          position: relative;
          height: 172rem;
          margin-bottom: 20rem;
          padding: 22rem;
          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
          .modal {
            position: relative;
            text-align: left;
            color: #fff;
            h4 {
              font-size: 22rem;
              line-height: 22rem;
              margin: 0 0 22rem;
            }
            p {
              font-size: 11rem;
            }
            .logo {
              justify-content: flex-end;
              .logo-box {
                width: 63rem;
                justify-content: center;
              }
              .el-image {
                width: 50rem;
              }
            }
          }
          &:nth-child(1) {
            .logo-box > .el-image {
              width: 52rem;
            }
          }
          &:nth-child(2) {
            .logo-box > .el-image {
              width: 30rem;
            }
          }
          &:nth-child(3) {
            .logo-box > .el-image {
              width: 62rem;
            }
          }
          &:nth-child(4) {
            .logo-box > .el-image {
              width: 40rem;
            }
          }
        }
      }
    }
    .two {
      padding: 0 24rem;
      h4 {
        font-size: 22rem;
        margin: 40rem 0 18rem;
      }
      p {
        font-size: 11rem;
        text-align: left;
        margin-bottom: 20rem;
      }
    }
    .three {
      background-color: #ebebeb;
      /deep/.section-main-slogan {
        h4 {
          margin: 30rem auto 45rem;
        }
      }
    }
    .four {
      /deep/.section-main-slogan {
        h4 {
          height: auto;
          line-height: 22rem;
          margin: 34rem auto 19rem;
        }
        p {
          font-size: 11rem;
          padding: 0 24rem;
        }
      }
      .lists {
        padding: 14rem 9rem;
        .items {
          position: relative;
          height: 161rem;
          margin-bottom: 12rem;
          .modal {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            .name {
              position: absolute;
              font-size: 11rem;
              color: #ffffff;
              bottom: 22rem;
              right: 34rem;
            }
          }
        }
      }
    }
    .five {
      background-color: #ebebeb;
      /deep/.section-main-slogan {
        h4 {
          height: auto;
          line-height: 22rem;
          margin: 34rem auto 19rem;
        }
        p {
          font-size: 11rem;
          padding: 0 24rem;
        }
      }
      .list {
        margin-top: 24rem;
        .item:nth-child(3) {
          .item-left {
            .el-image {
              width: 50rem;
              margin-left: -8rem;
            }
          }
        }
      }
    }
    .list {
      overflow: hidden;
      padding: 0 24rem 20rem;
      .item {
        background-color: #ffffff;
        align-items: center;
        padding: 38rem 17rem;
        margin-bottom: 8rem;
        .item-left {
          margin-right: 20rem;
          .el-image {
            width: 42rem;
            height: auto;
          }
        }
        .item-right {
          flex: 1;
          flex-shrink: 0;
          text-align: left;
          h4 {
            font-size: 22rem;
            margin: 0 0 11rem;
          }
          p {
            color: #474645;
            font-size: 11rem;
          }
        }
        &:nth-child(1),
        &:nth-child(4) {
          .item-left {
            .el-image {
              width: 50rem;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          padding: 38rem 20rem;
        }
      }
    }
  }
</style>
